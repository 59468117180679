import React from 'react';
import Button from '../../components/button/button';
import ListItem from '../../components/list-item/list-item';
import SectionTag from '../../components/section-tag/section-tag';
import HeaderImg from '../../assets/um-header-illustration.png';
import config from '../../../config.json';
import { ReferrerCookie } from '../../components/referrer/referrer';
import { useEffect } from 'react';

type Props = {};

const Hero = (props: Props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://trustmate.io/widget/api/9215277f-8edc-41c2-88ec-e3b2ebfd092a/script";
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="relative w-full overflow-hidden bg-blueLight py-6 lg:py-20">
      <div className="container relative z-10 mx-auto px-4">
        <div className="md:max-w-3xl">
          <div className="flex flex-col items-center lg:items-start">
            <SectionTag tag="h1">ubezpieczenia nieruchomości</SectionTag>
            <h2 className="mt-4 text-center text-4xl font-semibold text-text lg:mt-8 lg:text-left lg:text-6xl">
              Zaoszczędzisz nawet{' '}
              <span className="font-semibold leading-snug text-primary">
                40%
              </span>
              &nbsp;na ubezpieczeniu
            </h2>
          </div>
          <p className="my-5 hidden text-4xl font-medium text-primary lg:block">
            Gdzie Agenci ślą oferty tam Ty korzystasz!
          </p>
          <ul className="my-5 lg:my-0 lg:mb-5">
            <ListItem>
              Sprawdź <span className="font-medium">w kilka chwil</span> koszt
              polisy w wielu towarzystwach
            </ListItem>
            <ListItem>
              Wybierz najlepszą i oszczędź{' '}
              <span className="font-medium">nawet 40%</span> ceny ubezpieczenia
            </ListItem>
            <ListItem>
              <span className="font-medium">Kup zdalnie</span> lub tradycyjnie u
              Agenta
            </ListItem>
          </ul>
          <div className="text-center md:text-left">
            <a href={config.CALC_URL + props.referrer } target="_blank">
              <Button className="py-5">Porównaj ubezpieczenia</Button>
            </a>
            <div className="mt-8 flex max-md:justify-center min-h-[66px]" id="9215277f-8edc-41c2-88ec-e3b2ebfd092a"></div>
          </div>
          <div className="relative lg:absolute lg:top-1/2 lg:-right-[80%] lg:max-w-6xl lg:-translate-y-1/2 lg:px-0 xl:-right-[50%] 2xl:-right-[25%]">
            <img src={HeaderImg} alt="ubezpieczenie mieszkania" />
          </div>
          <p className="relative z-10 mt-5 font-secondary text-sm font-normal text-gray-400 lg:pr-5">
            *Porównując oferty ubezpieczycieli dostępne publicznie dla klientów,
            z dnia 2.11.2021 r. sprawdziliśmy, że można zaoszczędzić nawet
            40,16% na ubezpieczeniu dla dokładnie takiej samej nieruchomości.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
