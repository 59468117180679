import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../../components/button/button';
import Heading from '../../components/heading/heading';
import SectionTag from '../../components/section-tag/section-tag';

type Props = {};

const InsuranceRange = (props: Props) => {
  return (
    <div className="bg-blueLight ">
      <div className="container mx-auto flex flex-col items-center px-4 py-6 lg:py-20">
        <SectionTag tag="h2">ZAKRES UBEZPIECZENIA</SectionTag>
        <Heading tag="h3" className="text-center text-text">
          Co zawiera polisa mieszkaniowa?
        </Heading>
        <p className="mt-4 max-w-5xl text-center font-secondary text-lg text-text lg:mt-7">
          Ubezpieczenie nieruchomości przede wszystkim chroni mury i elementy
          stałe i domowe ruchomości. W zależności od potrzeb ubezpieczamy te
          ryzyka na jednej polisie lub na osobnych polisach. Na przykład jeśli
          mieszkanie komuś wynajmujemy i to nie nasze ruchomości są zagrożone,
          możemy oszczędzić na tym elemencie. Naszym lokatorom warto wtedy
          polecić, aby zawali polisę dla najemców.
        </p>
        <div className="mt-4 flex w-full snap-x gap-4 overflow-x-auto scroll-smooth lg:mt-10 lg:gap-8 xl:grid xl:grid-cols-3 xl:gap-20">
          <div className="flex min-w-[90%] snap-start scroll-ml-2 flex-col items-center rounded-md bg-white p-10 shadow-light lg:block lg:min-w-[45%]">
            <StaticImage
              src="../../assets/um-wall.svg"
              width={78}
              className="shrink-0"
              alt=""
              layout="fixed"
            />
            <h3 className="mt-4 text-center font-secondary text-lg font-bold text-text lg:text-left lg:text-[22px]">
              Co to jest ubezpieczenie murów i elementów stałych?
            </h3>
            <p className="mt-3 font-secondary text-base font-normal lg:text-lg">
              Ubezpieczenie stałych elementów i murów to ochrona na wypadek,
              gdyby konstrukcja naszej nieruchomości została zniszczona lub
              uszkodzona - cegły, bloczki, cement, dach, a nawet przytwierdzone
              na stałe: zlew czy toaleta.
            </p>
            <Button visualType="tertiary" className="mt-4" to="/blog/ubezpieczenie-murow-mieszkania-lub-domu-co-obejmuje" title="Ubezpieczenie murów i elementów stałych">
              Czytaj więcej
            </Button>
          </div>
          <div className="flex min-w-[90%] snap-start scroll-ml-2 flex-col items-center rounded-md bg-white p-10 shadow-light lg:block lg:min-w-[45%]">
            <StaticImage src="../../assets/um-kitchen.svg" width={78} alt="" />
            <h3 className="mt-4 text-center font-secondary text-lg font-bold text-text lg:text-left lg:text-[22px]">
              Co to jest ubezpieczenie ruchomości?
            </h3>
            <p className="mt-3 font-secondary text-base font-normal lg:text-lg">
              Ochrona ruchomości oznacza zabezpieczenie przedmiotów i mebli,
              które nie są przytwierdzona na stałe, np. kanapa, komoda, biurko
              czy telewizor i pralka. Chronić je można od zdarzeń, np. zalanie,
              pożar, a także kradzież i wandalizm.
            </p>
            <Button visualType="tertiary" className="mt-4" to="/blog/ubezpieczenie-ruchomosci-domowych-3-powody-dla-ktorych-warto-je-miec">
              Czytaj więcej
            </Button>
          </div>
          <div className="flex min-w-[90%] snap-start scroll-ml-2 flex-col items-center rounded-md bg-white p-10 shadow-light lg:block lg:min-w-[45%]">
            <StaticImage src="../../assets/um-flood.svg" width={78} alt="" />
            <h3 className="mt-4 text-center font-secondary text-lg font-bold text-text lg:text-left lg:text-[22px]">
              Co to są zdarzenia <br></br>losowe?
            </h3>
            <p className="mt-3 font-secondary text-base font-normal lg:text-lg">
              Zdarzenia losowe najdokładniej opisuje OWU danego Towarzystwa i
              musimy z tym paragrafem koniecznie się zapoznać. Występują tam
              wypadki takie jak: ogień, powódź, piorun, śnieg i lód aż po upadek
              statku powietrznego.
            </p>
            <Button visualType="tertiary" className="mt-4" to="/blog/zdarzenia-losowe-czym-sa-i-jak-uchronic-przed-nimi-mieszkanie-lub-dom" title="Zdarzenia losowe">
              Czytaj więcej
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InsuranceRange;
