import React from 'react';
import SectionTag from '../../components/section-tag/section-tag';
import Marquee from 'react-fast-marquee';
import { StaticImage } from 'gatsby-plugin-image';
import Heading from '../../components/heading/heading';
type Props = {};

const Companies = (props: Props) => {
  const logoWidth = 200;
  return (
    <div className="container mx-auto bg-white px-4 py-6 text-center lg:py-20">
      <div className="flex flex-col items-center justify-center">
        <SectionTag tag="h2">UBEZPIECZYCIELE PRZEDSTAWIAJĄCY OFERTY</SectionTag>

        <Heading tag="h3">
          Dopasuj najlepszą polisę spośród{' '}
          <span className="text-primary">30&nbsp;ofert</span> Ubezpieczycieli
        </Heading>
        <p className="mt-4 max-w-3xl font-secondary text-lg lg:mt-7">
          Skorzystaj i zamów sobie dopasowane oferty. Użyj prostego formularza,
          opisz swoją nieruchomość i usiądź wygodnie, czekając na oferty Twojego
          ubezpieczenia.
        </p>

        <div className="mt-4 lg:mt-7">
          <Marquee>
            <StaticImage
              src="../../assets/nationale-nederlanden.png"
              alt="Nationale Nederlanden"
              width={logoWidth}
              placeholder="tracedSVG"
            />
            <StaticImage
              src="../../assets/generali.png"
              width={logoWidth}
              alt="Generali"
              placeholder="tracedSVG"
            />
            <StaticImage
              src={'../../assets/aviva.png'}
              alt="Aviva"
              width={logoWidth}
              placeholder="tracedSVG"
            />
            <StaticImage
              src="../../assets/proama.png"
              alt="Proama"
              width={logoWidth}
              placeholder="tracedSVG"
            />
            <StaticImage
              src="../../assets/aegon.png"
              alt="Aegon"
              width={logoWidth}
              placeholder="tracedSVG"
            />
            <StaticImage
              src="../../assets/link4.png"
              alt="Link 4"
              width={logoWidth}
              placeholder="tracedSVG"
            />
            <StaticImage
              src="../../assets/pzu.png"
              alt="PZU"
              width={logoWidth}
              placeholder="tracedSVG"
            />
            <StaticImage
              src="../../assets/interpolska.png"
              alt="Interpolska"
              width={logoWidth}
              placeholder="tracedSVG"
            />
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Companies;
