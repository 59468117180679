import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Button from '../../components/button/button';
import Heading from '../../components/heading/heading';
import ListItem from '../../components/list-item/list-item';
import SectionTag from '../../components/section-tag/section-tag';
import config from '../../../config.json';
type Props = {};

const Savings = (props: Props) => {
  return (
    <div className="relative w-full overflow-hidden bg-white py-6 lg:py-20">
      <div className="container relative z-10 mx-auto px-4">
        <div className="md:max-w-3xl">
          <div className="flex flex-col items-center lg:items-start">
            <SectionTag tag="h2">JAK OSZCZĘDZIĆ NA POLISIE?</SectionTag>
            <Heading tag="h3">Co wpływa na cenę polisy?</Heading>
          </div>
          <p className="mt-4 max-w-5xl text-center font-secondary text-lg text-text lg:mt-7 lg:mb-4 lg:text-left">
            Na cenę twojej polisy na życie ma wpływ wiele czynników. Sprawdź,
            które dotyczą twojej nieruchomości:
          </p>
          <ul className="my-5 lg:my-0 lg:mb-5">
            <ListItem>Wielkość nieruchomości,</ListItem>
            <ListItem>
              Lokalizacja nieruchomości, np. teren zalewowy, Ilość stałych
              lokatorów,
            </ListItem>
            <ListItem>
              Zabezpieczenia nieruchomości, np. atestowane zamki, rolety
              antywłamaniowe,
            </ListItem>
            <ListItem>
              Data przeprowadzonego ostatnio remontu i jego zakres,
            </ListItem>
            <ListItem>
              Zakres ubezpieczenia, np. dodatkowa ochrona od kradzieży lub
              wandalizmu,
            </ListItem>
            <ListItem>
              Czy w nieruchomości dochodziło wcześniej do jakichś zdarzeń, np.
              zalań.
            </ListItem>
          </ul>
          <div className="text-center md:text-left">
            <a href={config.CALC_URL} target="_blank">
              <Button>Wylicz swoją polisę</Button>
            </a>
          </div>
          <div className="relative mt-6 text-center lg:absolute lg:top-1/2 lg:-right-[34%] lg:mt-0 lg:max-w-6xl lg:-translate-y-1/2 lg:px-0 xl:-right-[10%] 2xl:-right-[0%]">
            <StaticImage
              src="../../assets/um-section-2-illustration.png"
              alt="ubezpieczenie mieszkania"
              width={600}
              quality={90}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Savings;
