import React from 'react';
import SectionTag from '../../components/section-tag/section-tag';
import HomeSvg from '../../assets/um-home.svg';
import ApartmetsSvg from '../../assets/um-apartments.svg';
import CalendarSvg from '../../assets/um-calendar.svg';
import ClockSvg from '../../assets/um-clock.svg';
import LocationSvg from '../../assets/um-location.svg';
import StudetSvg from '../../assets/um-student.svg';
import Heading from '../../components/heading/heading';
type Props = {};

const Insured = (props: Props) => {
  const insuredCol1 = [
    {
      title: 'Właściciele nieruchomości',
      description:
        'Nieruchomość to bardzo często nasza najwartościowsza własność. Jej utrata oznacza poważne kłopoty, choćby przejściowe. Dlatego warto ubezpieczyć mieszkanie lub dom, aby zapewnić sobie środki',
      icon: HomeSvg,
    },
    {
      title: 'Wynajmujący',
      description:
        'Aby w razie najgorszego móc odnowić czy wręcz odbudować przedmiot naszego wynajmu - musimy nieruchomość ubezpieczyć. Bardzo często dzięki polisie nie musimy też obawiać się',
      icon: ClockSvg,
    },
    {
      title: 'Współlokatorzy',
      description:
        'Mieszkając razem w jednej nieruchomości nadal potrzebujemy ochrony swojej własności, np. komputera czy naszego wyposażenia. Dodatkowo, jeśli nawet przypadkiem wyrządzimy komuś szkodę,',
      icon: ApartmetsSvg,
    },
    {
      title: 'Wynajmujący na czas podróży',
      description:
        'Ubezpieczyć warto nieruchomość, którą opuszczamy na dłuższy czas, np. z powodu wyjazdu w delegację. Pęknięta rura w ścianie, koszty poszukiwania wycieku, zalanie sąsiada… to najgorszy',
      icon: LocationSvg,
    },
    {
      title: 'Najemcy',
      description:
        'Nieruchomość to bardzo często nasza najwartościowsza własność. Jej utrata oznacza poważne kłopoty, choćby przejściowe. Dlatego warto ubezpieczyć mieszkanie lub dom, aby zapewnić sobie środki',
      icon: CalendarSvg,
    },

    {
      title: 'Studenci',
      description:
        'Warto ubezpieczyć siebie przynajmniej w zakresie OC w życiu prywatnym, aby nasze zaniedbanie w mieszkaniu nie kosztowało nas słono. Przypadkiem wyrządzimy komuś szkodę.',
      icon: StudetSvg,
    },
  ];

  return (
    <div className="container mx-auto bg-white px-4 py-6 text-center lg:py-20">
      <div className="flex flex-col items-center justify-center">
        <SectionTag tag="h2">UBEZPIECZENIE NA MIARĘ</SectionTag>
        <Heading tag="h3">Kto potrzebuje ubezpieczenia mieszkania?</Heading>
        <p className="mt-4 max-w-4xl font-secondary text-lg lg:mt-7">
          Ubezpieczenie nieruchomości może być przydatne w różnych
          okolicznościach. Sprawdzamy kiedy i&nbsp;dlaczego warto mieć
          ubezpieczenie mieszkania, domu lub lokalu mieszkalnego.
        </p>
        <div className="mt-6 grid grid-cols-1 lg:grid-cols-2">
          {insuredCol1.map((item) => {
            return (
              <div className="mt-8 w-full">
                <div className="flex items-center">
                  <img src={item.icon} className="h-7 max-h-7" alt="" />
                  <h4 className="ml-4 font-secondary text-xl font-bold">
                    {item.title}
                  </h4>
                </div>
                <p className="mt-2 text-left font-secondary text-lg lg:pr-12">
                  {item.description}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Insured;
