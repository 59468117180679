import React, { ReactNode } from 'react';
import CheckedSvg from '../../assets/um-check.svg';

type Props = {
  children: ReactNode;
};

const ListItem = ({ children }: Props) => {
  return (
    <li className="flex items-center py-2 font-secondary text-base lg:text-xl">
      <img src={CheckedSvg} alt="" className="relative -top-[3px] pr-4" />
      <div className="">{children}</div>
    </li>
  );
};

export default ListItem;
