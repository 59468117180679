import React from 'react';
import SectionTag from '../../components/section-tag/section-tag';
import BathSvg from '../../assets/um-bathtub.svg';
import BrickSvg from '../../assets/um-brick.svg';
import UmbrellaSvg from '../../assets/um-insurance.svg';

import PlusSvg from '../../assets/um-plus-sign.svg';
import EqualSvg from '../../assets/um-equals-sign.svg';
import Heading from '../../components/heading/heading';

type Props = {};

const Costs = (props: Props) => {
  return (
    <div className="bg-blueLight ">
      <div className="container mx-auto flex flex-col items-center px-4 py-6 lg:py-20">
        <SectionTag tag="h2">KOSZT POLISY NIERUCHOMOŚCI</SectionTag>
        <Heading tag="h3" className="text-center">
          Ile kosztuje polisa mieszkaniowa?
        </Heading>
        <p className="mt-4 max-w-5xl text-center font-secondary text-lg  lg:mt-7">
          Wyliczenia dla polisy mieszkaniowej przy wartości nieruchomości 250
          000 zł
        </p>
        <div className="my-7 flex w-full flex-col  items-center justify-center lg:my-14 lg:flex-row lg:justify-evenly">
          <div className="w-60 rounded-md bg-white px-6 py-8 shadow-light lg:w-72 lg:px-12 lg:py-14">
            <div className="flex min-h-[120px] items-center justify-center">
              <img src={BrickSvg} alt="" className="mx-auto flex" />
            </div>
            <p className="mt-2 text-center font-secondary text-3xl font-bold">
              137 zł
            </p>
            <p className="text-center font-secondary text-xl font-bold">Mury</p>
          </div>
          <img src={PlusSvg} alt="" className="m-6 max-w-[66px] lg:m-4" />
          <div className="w-60 rounded-md bg-white px-6 py-8 shadow-light lg:w-72 lg:px-12 lg:py-14">
            <div className="flex min-h-[120px] items-center justify-center">
              <img src={BathSvg} alt="" className="mx-auto flex" />
            </div>
            <p className="mt-2 text-center font-secondary text-3xl font-bold">
              30 zł
            </p>
            <p className="text-center font-secondary text-xl font-bold">
              Elementy stałe
            </p>
          </div>
          <img src={EqualSvg} alt="" className="m-6 max-w-[66px] lg:m-4" />
          <div className="w-60 rounded-md bg-white px-6 py-8 shadow-light lg:w-72 lg:px-12 lg:py-14">
            <div className="flex min-h-[120px] items-center justify-center">
              <img src={UmbrellaSvg} alt="" className="mx-auto flex" />
            </div>
            <p className="mt-2 text-center font-secondary text-3xl font-bold">
              167 zł
            </p>
            <p className="text-center font-secondary text-xl font-bold">
              Cena łączna
            </p>
          </div>
        </div>
        <p className="mt-4 max-w-3xl text-center font-secondary text-base  lg:mt-7">
          Nawet 40% oszczędności na koszcie Twojej polisy nieruchomości. Zanim
          kupisz, porównaj oferty. Wyliczone dla wartości mieszkania
          250&nbsp;000&nbsp;zł przy cenie elementów stałych 35&nbsp;000&nbsp;zł.
        </p>
      </div>
    </div>
  );
};

export default Costs;
