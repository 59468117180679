import React from 'react';
import SectionTag from '../../components/section-tag/section-tag';
import OneSvg from '../../assets/um-1-icon.svg';
import TwoSvg from '../../assets/um-2-icon.svg';
import ThreeSvg from '../../assets/um-3-icon.svg';
import FourSvg from '../../assets/um-4-icon.svg';
import Heading from '../../components/heading/heading';

type Props = {};

const WhereFind = (props: Props) => {
  const items = [
    {
      title: 'Zdobądź więcej ofert',
      description:
        'Aby upewnić się, że nie przepłacasz potrzebujesz co najmniej 2 ofert z różnych Towarzystw Ubezpieczeń, a dodatkowe oferty to dodatkowa pewność.',
      icon: OneSvg,
    },
    {
      title: 'Pamiętaj o historii szkodowości',
      description:
        'Jeśli w nieruchomości nie dochodziło do szkód, możesz liczyć na lepszą ofertę cenową. W długim terminie dbanie o swoją nieruchomość popłaca.',
      icon: TwoSvg,
    },
    {
      title: 'Unikaj opłat ratalnych',
      description:
        'Kiedy płacimy jednorazowo często otrzymujemy zniżkę. Rozkładając składkę na 12 rat może się okazać, że zapłaciliśmy 10% więcej za polisę.',
      icon: ThreeSvg,
    },
    {
      title: 'Zapytaj doradcę o zniżkę',
      description:
        'Zanim kupisz, zapytaj. Nie kosztuje to nic, a bywa tak, że Doradca chcąc utrzymać z nami dobre relacje wynegocjuje zgodę dyrektora na zniżkę.',
      icon: FourSvg,
    },
  ];
  return (
    <div className="relative w-full overflow-hidden bg-blueLight py-6 lg:py-20">
      <div className="container relative z-10 mx-auto px-4">
        <div className="flex flex-col items-center">
          <SectionTag>GDZIE SIĘ UBEZPIECZYĆ?</SectionTag>
          <Heading className="text-center">
            Jak znaleźć tańsze ubezpieczenie mieszkania?
          </Heading>
        </div>
        <div className=" grid grid-cols-1 lg:grid-cols-2">
          {items.map((item) => {
            return (
              <div className="mt-12 flex items-start">
                <img
                  src={item.icon}
                  alt=""
                  className="mr-4 w-10 lg:mr-10 lg:w-24"
                />
                <div className="mt-[5px] lg:mt-0">
                  <h3 className="font-secondary text-xl font-bold text-text">
                    {item.title}
                  </h3>
                  <p className="mt-3 font-secondary text-lg text-text lg:pr-12">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhereFind;
