import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import Heading from '../../components/heading/heading';
import SectionTag from '../../components/section-tag/section-tag';

type Props = {};

const Insurance = (props: Props) => {
  return (
    <div className="relative overflow-hidden">
      <div className="bg-blueLight ">
        <div className="container mx-auto px-4 py-6 lg:py-20">
          <div className="relative z-10 lg:ml-[30%]">
            <div className="flex flex-col items-center lg:items-start">
              <SectionTag tag="h2">Ubezpieczenie nieruchomości</SectionTag>
              <Heading tag="h3" className="text-center lg:text-left">
                Co to jest ubezpieczenie mieszkania?
              </Heading>
            </div>
            <p className="mt-4 text-center font-secondary text-lg lg:mt-7 lg:text-left">
              Ubezpieczenie mieszkania czy domu (nawet letniskowego) to pewność,
              że kiedy twoja nieruchomość ulegnie zniszczeniu lub poważnemu
              uszkodzeniu, nie zostaniesz bez pomocy! To ochrona w takich
              przypadkach jak: pożar lub powódź, a także wtedy, kiedy wskutek
              kradzieży Twoja własność zginie lub zostanie zniszczona.
            </p>
          </div>
          <div className="relative z-0 mt-10  lg:absolute lg:-left-[700px] lg:top-1/2 lg:mt-0 lg:max-w-5xl  lg:-translate-y-1/2 xl:-left-[650px] 2xl:-left-[600px]">
            <StaticImage
              src="../../assets/um-section-1-illustration.png"
              alt="Ubezpieczenie nieruchomości"
              width={1024}
              quality={95}
            />
          </div>
        </div>
      </div>
      <div className="bg-white ">
        <div className="container mx-auto px-4 py-6 lg:py-20">
          <div className="relative z-10 lg:ml-[30%]">
            <div className="flex flex-col items-center lg:items-start">
              {' '}
              <SectionTag>UBEZPIECZENIE DOBROWOLNE</SectionTag>
              <Heading tag="h3" className="text-center lg:text-left">
                Komu potrzebne jest ubezpieczenie nieruchomości?
              </Heading>
            </div>

            <p className="mt-4 text-center font-secondary text-lg lg:mt-7 lg:text-left">
              Tak jak ubezpieczenie na życie, ubezpieczenie mieszkania jest
              dobrowolne, może tylko poza przypadkiem ubezpieczenia do kredytu
              hipotecznego, kiedy to bank wymaga od nas polisy. Jeśli więc nie
              jest obowiązkowe, to po co ubezpieczać swoją nieruchomość, komu to
              potrzebne?
            </p>
            <p className="mt-4 text-center font-secondary text-lg lg:text-left">
              Każdy, kto obawia się lub kiedyś doświadczył zalania podczas
              ulewnego deszczu, uszkodzeń wskutek coraz częstszych wichur, czy
              zniszczeń w wyniku własnego zaniedbania, powinien mieć
              ubezpieczenie nieruchomości – pozwoli ono zaoszczędzić nam
              pieniądze i wysiłek podczas usuwania szkód.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Insurance;
