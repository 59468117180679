import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import Layout from '../components/layout/layout';
import Seo from '../components/seo/seo';
import Companies from '../domain/companies/companies';
import Costs from '../domain/costs/costs';
import Hero from '../domain/hero/hero';
import Insurance from '../domain/insurance/insurance';
import Insured from '../domain/insured/insured';
import InsuranceRange from '../domain/range/range';
import Savings from '../domain/savings/savings';
import WhereFind from '../domain/where-find/where-find';
import {ReferrerCookie} from "../components/referrer/referrer";

const IndexPage = () => {
  const referrer = ReferrerCookie();
  const { wpPage } = useStaticQuery(graphql`
    query IndexSeo {
      wpPage(slug: { eq: "strona-glowna" }) {
        seo {
          metaDesc
          title
          focuskw
        }
      }
    }
  `);
  return (
    <>
      <Layout>
        <Seo
          title={wpPage.seo.title}
          description={wpPage.seo.metaDesc}
          keywords={wpPage.seo.focuskw}
          pathname="/"
        />
        <Hero referrer={referrer}/>
        <Companies />
        <Insurance />
        <InsuranceRange />
        <Insured />
        <Costs />
        <Savings />
        <WhereFind />
      </Layout>
    </>
  );
};

export default IndexPage;
